import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {Context as CocktailContext} from '../context/CocktailContext';
import {generatePdf} from '../network/apiactions';
import IngredientRow from "./widgets/IngredientRow";

const CalculationScreen = () => {
    const {setDownloadLink,clearSelectedCocktails,state:{cocktails,selectedCocktails,guests}} = useContext(CocktailContext);
    const [ingredientMap,setIngredientMap] = useState({});
    const [selectedCocktailsState,setSelectedCocktailsState] = useState([]);
    const [generatingPdf,setGeneratingPdf] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        let ingredients = {};
        //Transfer the cocktails list to a state variable so it can be cleared after processing
        setSelectedCocktailsState(selectedCocktails);

        for(let i=0;i<selectedCocktails.length;i++) {
            const cocktail = cocktails.find(c => c.id == selectedCocktails[i]);
            for(let j=0;j<cocktail.ingredients.length;j++) {
                let amount = 0;
                if(ingredients[cocktail.ingredients[j].ingredient]) {
                    amount = ingredients[cocktail.ingredients[j].ingredient]
                }
                ingredients[cocktail.ingredients[j].ingredient] = amount + (cocktail.ingredients[j].amount * guests);
            }
        }
        setIngredientMap(ingredients);
        clearSelectedCocktails();
    },[]);

    const downloadPdf = async () => {
        setGeneratingPdf(true);
        const pdfLinkData = await generatePdf(selectedCocktailsState);
        setDownloadLink(pdfLinkData.data.download_url);
        navigate('/downloadpdf');
        setGeneratingPdf(false);
    }

    return (
        <div>
            {
                generatingPdf
                ? <p>Loading...</p>
                : guests == 0
                    ? <>
                        <h1>No Guests are Selected</h1>
                        <button className="btn-spaced" onClick={() => navigate('/')}>Return to Home Screen</button>
                    </>
                    : <>
                        <h1>{guests} Guests</h1>
                        {
                            Object.keys(ingredientMap).length > 0
                            ? <>
                                                <div className="ingredients-section">
                                <h2>Ingredients</h2>
                                <div className="ingredients-list">
                                {
                                    Object.keys(ingredientMap).map(ingredient => (
                                        <IngredientRow key={ingredient} ingredient={ingredient} amount={ingredientMap[ingredient]} />

                                    ))
                                }
                                </div>
                                </div>
                                <h2>This will make {guests} of</h2>
                                <div className="cocktail-name-list">
                                    {
                                        selectedCocktailsState.map(cocktailId => (
                                            <p key={String(cocktailId)}>{cocktails.filter(c => c.id === cocktailId)[0].name}</p>
                                        ))
                                    }
                                </div>
                                <div className="download-pdf-area">
                                    <p>Download a PDF displaying these cocktails and how to make them.</p>
                                    <div className="btn-group">
                                        <button className="btn-spaced" onClick={() => downloadPdf()}>Download PDF</button>
                                        <button className="btn-spaced" onClick={() => navigate('/')}>Return to Home Screen</button>
                                    </div>
                                </div>
                            </>
                            : <>
                                <h2>You haven't selected any cocktails</h2>
                                <button className="btn-spaced" onClick={() => navigate('/')}>Return to Home Screen</button>
                            </>
                        }
                    </>
            }

        </div>
    );
}

export default CalculationScreen;