import {conn} from './api';

const getCocktails = () => {
    return new Promise(async (resolve,reject) => {
        await conn.get('/allcocktails/').then((res) => {
            return resolve(res);
        }).catch((err) => {
            console.log('Error', err);
            return resolve(err.data);
        });
    });
}

const generatePdf = (payload) => {
    return new Promise(async (resolve,reject) => {
        await conn.post('/cocktails/createpdf/',payload).then((res) => {
            return resolve(res);
        }).catch((err) => {
            console.log('Error', err);
            return resolve(err.data);
        });
    });
}

export {getCocktails, generatePdf};