import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import {Context as CocktailContext} from '../context/CocktailContext';
import AmountSelector from "./widgets/AmountSelector";
import CocktailTile from "./widgets/CocktailTile";

const CocktailsList = () => {
    const {increaseAmount, decreaseAmount,state:{cocktails,selectedCocktails}} = useContext(CocktailContext);
    const navigate = useNavigate();
    const [setIsSelcted,isSelected] = useState(false);

    const calculateIngredients = () => {
        navigate('/calculate');
    }


    return(
        <div className="cocktails-page">
            <h2>Select from the cocktails below and choose the number of guests attending.</h2>
            <div className="cocktail-tiles">
                {
                    cocktails.map(cocktail => (
                        <div key={cocktail.id} className="cocktail-tile" onClick={null} >
                            <CocktailTile id={cocktail.id} name={cocktail.name} imageName={cocktail.imagename} />
                        </div>
                    ))
                }
            </div>
            <AmountSelector decreaseAmount={decreaseAmount} increaseAmount={increaseAmount} />
            <button id="calculate-button" onClick={() => calculateIngredients()}>Calculate</button>
        </div>

    );
}

export default CocktailsList;