//Import images
import kamikaze from '../../assets/kamikaze.jpg';
import longislandicetea from '../../assets/longislandicetea.jpg';
import maitai from '../../assets/maitai.jpg';
import mohito from '../../assets/mohito.jpg';
import pinacolada from '../../assets/pinacolada.jpg';
import piscosour from '../../assets/piscosour.jpg';
import saltydog from '../../assets/saltydog.jpg';
import screwdriver from '../../assets/screwdriver.jpg';
import tequilasunrise from '../../assets/tequilasunrise.jpg';

import { useState, useContext } from 'react';
import AmountSelector from './AmountSelector';
import {Context as CocktailContext} from '../../context/CocktailContext';

const GetCocktailImage = (imageName) => {
    switch (imageName) {
        case 'kamikaze.jpg':
            return kamikaze;
        case 'longislandicetea.jpg':
            return longislandicetea;
        case 'maitai.jpg':
            return maitai;
        case 'mohito.jpg':
            return mohito;
        case 'pinacolada.jpg':
            return pinacolada;
        case 'piscosour.jpg':
            return piscosour;
        case 'saltydog.jpg':
            return saltydog;
        case 'screwdriver.jpg':
            return screwdriver;
        case 'tequilasunrise.jpg':
            return tequilasunrise;
        default:
            break;
    }
}

const CocktailTile = (props) => {
    const {addToSelectedCocktails,removeFromSelectedCocktails,state:{cocktails,selectedCocktails}} = useContext(CocktailContext);
    const [isSelected,setIsSelcted] = useState(false);

    const toggleSelectedCocktails = (cocktailId) => {
        if(selectedCocktails.includes(cocktailId)) {
            removeFromSelectedCocktails(cocktailId);
            setIsSelcted(false);
        } else {
            addToSelectedCocktails(cocktailId);
            setIsSelcted(true);
        }
    } 

    return(
        <>
            <div id={props.id} className= {isSelected?"cocktail-image-selected":"cocktail-image"}>
                {
                    <img src={GetCocktailImage(props.imageName)} alt={props.name} />
                }
                
            </div>
            <button onClick={() => toggleSelectedCocktails(props.id)}>{isSelected?'Deselect':'Select'}</button>
        </>
    );
}

export default CocktailTile;