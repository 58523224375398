import { useState, useEffect, useContext } from "react";
import {Context as CocktailContext} from '../../context/CocktailContext'; 


const AmountSelector = (props) => {
    const {setGuests,state:{guests}} = useContext(CocktailContext);
    const [guestsValue,setGuestValue] = useState(guests);

    const decreaseAmount = () => {
        //props.decreaseAmount();
        
        if(guestsValue > 0) {
            setGuests(guestsValue - 1);
            setGuestValue(guestsValue - 1);
            document.querySelector('#guests-amount').value = guestsValue - 1;
        }
    }

    const increaseAmount = () => {
        //props.increaseAmount();
        setGuests(guestsValue + 1);
        setGuestValue(guestsValue + 1);
        document.querySelector('#guests-amount').value = guestsValue + 1;
    }


    return(
        <div className="amount-container">
            <p>Number of Guests</p>
            <button className='amount-btn' onClick={() => decreaseAmount()}>-</button>
            <input id='guests-amount' type="text" defaultValue={guests} />
            <button className='amount-btn' onClick={() => increaseAmount()}>+</button>
        </div>
    );  
}


export default AmountSelector;