import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import {Context as CocktailContext} from '../context/CocktailContext';

const DownloadScreen = () => {
    const {state:{downloadLink}} = useContext(CocktailContext);
    const navigate = useNavigate();

    return (
        <div>
            <p>Please click <a href={downloadLink}>here</a> to download the PDF. Thankyou.</p>
            <button className="btn-spaced" onClick={() => navigate('/')}>Return to Home Page</button>
        </div>
    );
}

export default DownloadScreen;