import './App.css';
import { Routes, Route} from "react-router-dom";
import { useEffect, useContext, useState } from 'react';
import {getCocktails} from './network/apiactions';
import {Context as CocktailContext} from './context/CocktailContext'; 
import CocktailsList from './components/CocktailsList';
import CalculationScreen from './components/CalculationScreen';
import DownloadScreen from './components/DownloadScreen';
import Layout from './components/Layout';
import cocktailLogo from './assets/cocktail_logo.png';


function App() {
  const {setCocktails} = useContext(CocktailContext);
  const [loaded,setLoaded] = useState(false);

  const loadCocktails = async () => {
    const cocktails = await getCocktails();
    setCocktails(cocktails.data);
    setLoaded(true);
  }

  useEffect(() => {
    loadCocktails();
  },[]);

  return (
    <div className="App">
      <header className="App-header">
        <div className="logo-container">
          <img src={cocktailLogo} />
        </div>
        <h1>Cocktail Party</h1>
      </header>
      <main>
      { loaded 
        ? <Routes>
            <Route path="/" element={<Layout />}>
                <Route index element={<CocktailsList />} />
                <Route path="/calculate" element={<CalculationScreen />} />
                <Route path="/downloadpdf" element={<DownloadScreen />} />
            </Route>
        </Routes>
        : <p>Loading...</p> }
      </main>
      <footer>
        <p>by Chris Briant &copy; {new Date().getFullYear()}</p>
      </footer>
    </div>
  );
}

export default App;
