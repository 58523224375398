import createDataContext from './createDataContext';

const defaultState = {
  cocktails: [],
  selectedCocktails : [],
  guests:0,
  downloadLink: '',
};

const cocktailReducer = (state,action) => {
  let newSelectedCocktails = [];

  switch(action.type) {
    case 'setCocktails':
        return {...state,cocktails:action.payload};
    case 'addToSelectedCocktails':
      newSelectedCocktails = [...state.selectedCocktails,action.payload];
      return {...state,selectedCocktails:newSelectedCocktails};
    case 'removeFromSelectedCocktails':
      newSelectedCocktails = state.selectedCocktails.filter(c  => c !== action.payload);
      return {...state,selectedCocktails:newSelectedCocktails};
    case 'clearSelectedCocktails':
      return {...state,selectedCocktails:[]};
    case 'setDownloadLink':
      return {...state,downloadLink:action.payload};
    case 'setGuests':
      return {...state,guests:action.payload};
    default:
      return defaultState;
  }
};

//Setters

const setCocktails = (dispatch) => (cocktails) => {
  dispatch({type:'setCocktails', payload:cocktails});
}

const addToSelectedCocktails = (dispatch) => (cocktail) => {
  dispatch({type:'addToSelectedCocktails', payload:cocktail});
}

const removeFromSelectedCocktails = (dispatch) => (cocktail) => {
  dispatch({type:'removeFromSelectedCocktails', payload:cocktail});
}

const clearSelectedCocktails = (dispatch) => () => {
  dispatch({type:'clearSelectedCocktails', payload:null});
}

const setDownloadLink = (dispatch) => (link) => {
  dispatch({type:'setDownloadLink', payload:link});
}


const setGuests = (dispatch) => (guests) => {
  dispatch({type:'setGuests', payload:guests});
}


export const {Provider, Context} = createDataContext (
    cocktailReducer,
    { setCocktails ,addToSelectedCocktails,removeFromSelectedCocktails,
      setGuests, setDownloadLink, clearSelectedCocktails },
    {...defaultState}
);