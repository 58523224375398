const IngredientRow = (props) => {
    return(
        <>        
            <div><strong>{props.ingredient}</strong> :</div>
            <div className="amount-column">{props.amount}</div>
        </>

    );
}

export default IngredientRow